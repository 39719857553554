<template>
    <div class="video">
        <div class="list">
            <div class="item" v-for="item in videoList" :key="item.url" @click="clkVideo(item.url)">
                <!-- <img src="../../../assets/images/video.jpg" alt=""> -->
                <video :src="item.url" controls="controls"></video>
                <div class="play" @click="showVideo"><span>播放</span></div>
            </div>
        </div>
        <div class="modal" v-if="videoShow">
            <div class="video-content">
                <b @click="hideVideo">×</b>
                <video :src="videoUrl" controls="controls"></video>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { getVideo } from '@/api/news'
export default {
    setup() {
        const videoShow = ref(false)
        const videoList = ref([])
        const videoUrl = ref('')

        const hideVideo = () => {
            videoShow.value = false
        }

        const showVideo = () => {
            videoShow.value = true
        }

        const getVideoList = async() => {
            let data = await getVideo()
            if(data.status === 200) {
                videoList.value = data.data.data
            }
        }
        getVideoList()

        const clkVideo = (e) => {
            videoUrl.value = e
        }

        return {
            videoShow,
            videoList,
            videoUrl,
            hideVideo,
            showVideo,
            clkVideo
        }
    },
}
</script>

<style lang="scss">
    .list {
        video::-webkit-media-controls-fullscreen-button {display: none;}
        video::-webkit-media-controls-play-button {display: none;}
        video::-webkit-media-controls-timeline {display: none;}
        video::-webkit-media-controls-current-time-display{display: none;}
        video::-webkit-media-controls-time-remaining-display {display: none;}
        video::-webkit-media-controls-mute-button {display: none;}
        video::-webkit-media-controls-toggle-closed-captions-button {display: none;}
        video::-webkit-media-controls-volume-slider {display: none;}
        
    }
</style>
<style lang="scss" scoped>
    .video {
        margin: 40px 20px;
        .list {
            display: flex;
            flex-wrap: wrap;
            .item {
                width: calc(33.33% - 20px);
                margin-right: 30px;
                margin-bottom: 50px;
                position: relative;
                img,video {
                    width: 100%;
                    height: 190px;
                }
                .play {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 10;

                    span {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: #fff;
                        text-align: center;
                        line-height: 65px;
                        width: 65px;
                        height: 65px;
                        border-radius: 50%;
                        background: rgba($color: #000000, $alpha: .5);
                    }
                }
            }
            .item:nth-child(3n) {
                margin-right: 0;
            }
        }
        .modal {
            position: fixed;
            margin: 0;
            padding: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 100;
            height: 100%;
            background: rgba($color: #000000, $alpha: .7);

            .video-content {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 1000px;

                b{
                    width: 37px;
                    height: 38px;
                    line-height: 34px;
                    text-align: center;
                    color: #fff;
                    font-weight: normal;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    z-index: 101;
                    background: rgba($color: #000000, $alpha: .7);
                    border-radius: 50%;
                    font-size: 30px;
                    cursor: pointer;
                }

                video {
                    width: 100%;
                }
            }
        }
    }
</style>